import { makeStyles, withStyles, Tooltip } from "@material-ui/core";


export const docTypeButton = {
  width: "160px",
  height: "35.8px",
  objectFit: "contain",
  borderRadius: "4px",
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.09)',
  border: 'solid 0.5px #dcddd9',
  backgroundColor: '#f5f7f2',
  fontSize: "12px",
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "center",
  verticalAlign: "middle",
  color: "#354052",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const disabledDocTypeButton = {
  boxShadow: 'none',
  border: "solid 0.5px #b1b2b0",
  backgroundColor: "#c6c7c4",
  color: "#b1b2b0",
};

export const uploadFileIcon = {
  width: "14px",
  height: "17px",
  objectFit: "contain",
  verticalAlign: "middle",
};

export const uploadedDocWrapper = {
  margin: '0 0 -8px -14px',
  color: "#3ea848",
  display: "flex",
  alignItems: "center",
};

export const uploadedDocText = {
  fontSize: "14px",
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  letterSpacing: "normal",
  textAlign: "left",
};

export const failedUploadDocWrapper = {
  width: "190px",
  height: "38px",
  color: "#e33e3a",
  display: "flex",
  alignItems: "center",
};

export const failedUploadDocText = {
  height: "17px",
  margin: "0 7px 0",
  fontSize: "14px",
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  letterSpacing: "normal",
  textAlign: "left",
};

export const retryText = {
  width: "36px",
  height: "17px",
  fontSize: "14px",
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  textAlign: "left",
  color: "#707070",
  textDecoration: "underline",
  cursor: "pointer",
};

export const uploadingDocText = {
  height: "17px",
  margin: "0 0 7px",
  fontSize: "14px",
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  textAlign: "left",
  color: "#707070",
};

export const modalWrapper = {
  backgroundColor: "#fff",
  width: "176px",
  height: "100%",
  padding: "15px 16px 16px",
  borderRadius: 4,
  boxShadow: "0 5px 10px 0 #b5b6b4",
};

export const modalText = {
  fontSize: "12px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.21",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#686868",
};

export const dropzoneStyles = {
  backgroundColor: '#fcfcfb',
  border: "dashed 1px #cad0d8",
  borderRadius: "4px",
  height: '100%',
  margin: "13px 0 0",
  padding: "15px 12px",
  opacity: "0.57",
  position: "relative",
  textAlign: "center",
  width: "100%",
  ":hover": {backgroundColor: "#fff5e5"},
};

export const dropzoneContent = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  left: "50%",
  position: "absolute",
  top: "50%",
  transform: "translateX(-50%) translateY(-50%) ",
  width: "100%",
  height: '100%',
};

export const dropzoneText = {
  fontSize: "13px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  letterSpacing: "normal",
  color: "#686868",
};

export const buttonText = {
  fontSize: "10px",
  fontWeight: 900,
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
};

export const CustomTooltip = withStyles({
  tooltip: {
    width: 170,
    height: 42,
    padding: "14px 17px 19px 22px",
    fontSize: 12,
    fontWeight: "normal",
    backgroundColor: '#01666c',
    color: '#fff',
  },
  arrow: {
     color: '#01666c'
  },
})(Tooltip);

export const useStyles = makeStyles((theme) => ({
  ClickButton: {
    width: "74px",
    height: "29px",
    marginTop: "11px",
    borderRadius: "2px",
    border: "solid 1px #426d61",
    backgroundColor: "#426d61",
    textTransform: "none",
    display: "inline-block",
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    color: "#fff",
  },

  UploadButton: {
    width: "91px",
    height: "32px",
    marginTop: "11px",
    borderRadius: "4px",
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.09)',
    border: 'solid 0.5px #dcddd9',
    backgroundColor: "#f5f7f2",
    textTransform: "none",
    display: "inline-block",
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    color: "#354052",
    "&:hover": {
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.2)',
      border: 'solid 0.5px #dcddd9',
      color: "#354052",
      backgroundColor: "#f5faef",
    },
  },

  ProgressBar: {
    height: "8px",
    width: 140,
    borderRadius: "4px",
    backgroundColor: "#f7f8f6",
    border: "solid 1px #ecedeb",
  },
}));
